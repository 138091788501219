<template>
  <div>
    <v-card class="login__container mx-auto">
      <v-card-text>
        <div class="container text-center mt-2">
          <img class="logo" :src="logoSvg" :alt="logoAlt" />
        </div>
        <h1 class="login__info-text ma-6 ma-lg-16 mt-lg-8">Welcome!</h1>
        <subtitle-1 class="mt-6 ml-6 mr-6 ml-lg-16 mr-lg-16 row remind__info">Choose your language:</subtitle-1>
        <v-container class="mt-6">
          <v-row>
            <v-col cols="12" class="text-center">
             <v-btn
                v-for="lang in languages"
                :key="lang.code"
                @click="selectLanguage(lang.code)"
                class="ma-2 mb-6 flag-button"
                :class="{ 'flag-button--active': selectedLanguage === lang.code }"
                cols="12"
                outlined
              >
                <img :src="lang.flagIconSrc" alt="Flag" class="flag-icon" />
                <h3 class="semibold-font">{{ lang.name }}</h3>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-col cols="12" lg="12" class="text-center">
          <button
            type="submit"
            :disabled="!selectedLanguage"
            @click="confirmLanguage"
            class="main-button main-button-fixed"
          >CONFIRM</button>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { analyticsModule, getIsExpoApp } from 'cuf-expo-bridge'
import { recoverUtm } from '@/utils/recoverUtm'

export default {
  name: 'SelectLanguage',
  data () {
    return {
      selectedLanguage: null,
      languages: [
        { code: 'pl', flagIconSrc: require('@/assets/pl-flag.svg'), name: 'POLISH' },
        { code: 'ro', flagIconSrc: require('@/assets/ro-flag.svg'), name: 'ROMANIAN' },
        { code: 'bg', flagIconSrc: require('@/assets/bg-flag.svg'), name: 'BULGARIAN' }
      ]
    }
  },
  computed: {
    logoSvg () {
      return require('@/assets/euro-tax.pl.svg')
    },
    logoAlt () {
      return 'Euro-tax.pl'
    }
  },
  methods: {
    selectLanguage (code) {
      this.selectedLanguage = code
    },

    confirmLanguage () {
      this.$i18n.locale = this.selectedLanguage
      localStorage.setItem('userLanguage', this.selectedLanguage)
      analyticsModule.initAnalytics({
        title: this.$t('analytics.title'),
        acceptAll: this.$t('analytics.acceptAll'),
        denyAll: this.$t('analytics.denyAll'),
        save: this.$t('analytics.save'),
        consents: {
          analyticsStorage: this.$t('analytics.consents.analyticsStorage'),
          adStorage: this.$t('analytics.consents.adStorage'),
          adUserData: this.$t('analytics.consents.adUserData'),
          adPersonalization: this.$t('analytics.consents.adPersonalization')
        }
      }).then(() => {
        recoverUtm().then(data => {
          analyticsModule.logEvent({ name: 'campaign_details', params: data })
          if (getIsExpoApp()) {
            localStorage.setItem('utmSource', data.source)
          }
        })
      })

      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .flag-icon {
    width: 91px;
    height: 57px;
    margin-right: 10px;
  }

  .flag-button {
    background-color: #F4F4F4 !important;
    height: 56px !important;
  }

  .v-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align text and icon to the left */
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s, box-shadow 0.3s;
    padding: 10px 20px; /* Add padding for better spacing */
    width: 100%; /* Make buttons full width */
    text-transform: uppercase; /* Make text uppercase */
    font-weight: bold; /* Bold text */
  }

  .flag-button--active {
    border-color: #ff4081; /* Example active color */
    box-shadow: 0 0 10px rgba(255, 64, 129, 0.5); /* Add shadow for active button */
  }
</style>
